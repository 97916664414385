var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0 proposta" },
        [
          _c("breadcrumb", {
            attrs: { title: _vm.tituloTela, actualPage: _vm.subTituloTela },
          }),
        ],
        1
      ),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                [
                  _c(
                    "form-wizard",
                    {
                      ref: "formWizard",
                      staticClass: "proposta",
                      attrs: {
                        color: "rgba(var(--vs-warning), 1)",
                        title: null,
                        subtitle: null,
                        "start-index": _vm.loaded
                          ? (_vm.proposta || {}).StatusId === 3
                            ? 3
                            : 0
                          : 0,
                        finishButtonText: "Submit",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "step",
                            fn: function (props) {
                              return [
                                _c("wizard-step", {
                                  key: props.tab.title,
                                  attrs: {
                                    tab: props.tab,
                                    transition: props.transition,
                                    checked:
                                      (_vm.proposta || {}).StatusId === 3,
                                    disabled:
                                      (_vm.proposta || {}).StatusId === 3,
                                    index: props.index,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "footer",
                            fn: function (props) {
                              return [
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "wizard-footer-left" },
                                    [
                                      props.activeTabIndex > 0 &&
                                      props.activeTabIndex < 3
                                        ? _c(
                                            "vs-button",
                                            {
                                              attrs: { color: "primary" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return props.prevTab()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "font-semibold",
                                                },
                                                [_vm._v("Voltar")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "wizard-footer-right" },
                                    [
                                      props.isLastStep && _vm.podeEmitirApolice
                                        ? _c(
                                            "vs-button",
                                            {
                                              staticClass: "button-default",
                                              attrs: { type: "filled" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.enviarEventoAppInsights(
                                                    "desistir"
                                                  ),
                                                    _vm.$router.push({
                                                      name: "propostas",
                                                    })
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "font-semibold",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Desistir\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.isLastStep
                                        ? _c(
                                            "vs-button",
                                            {
                                              staticClass: "button-default",
                                              attrs: { type: "filled" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.enviarEventoAppInsights(
                                                    "salvar-proposta"
                                                  ),
                                                    _vm.salvarProposta()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "font-semibold",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Salvar Proposta\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !props.isLastStep &&
                                      _vm.proposta.Status !== 0 &&
                                      props.activeTabIndex != 2
                                        ? _c(
                                            "vs-button",
                                            {
                                              class: {
                                                "fix-button":
                                                  _vm.proposta.Status !== 0 &&
                                                  props.activeTabIndex === 2,
                                              },
                                              attrs: { color: "primary" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return props.nextTab()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "font-semibold",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Prosseguir\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.isLastStep &&
                                      _vm.podeSolicitarAjuste &&
                                      1 == 0
                                        ? _c(
                                            "vs-button",
                                            {
                                              staticClass: "button-default",
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.popupSolicitacao = true
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {}, [
                                                _vm._v(
                                                  "Solicitação especial de ajuste na proposta"
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.isLastStep && _vm.podeEmitirApolice
                                        ? _c(
                                            "vs-button",
                                            {
                                              staticClass: "button-default",
                                              attrs: { type: "filled" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.imprimirMinuta()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "font-semibold",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Imprimir minuta\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.isLastStep && _vm.podeEmitirApolice
                                        ? _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                disabled: invalid,
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.emitirApolice()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-semibold pl-2",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Emitir apólice\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.podeFazerDownloadLaudoEmissao
                                        ? _c(
                                            "vs-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadLaudoEmissao()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                Download do laudo de emissão\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "vs-popup",
                                        {
                                          attrs: {
                                            active: _vm.popupSolicitacao,
                                            title: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "text-primary pb-3 font-semibold",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Solicitação especial de ajuste na proposta\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "font-semibold py-2",
                                            },
                                            [_vm._v("Observação")]
                                          ),
                                          _c("vs-textarea", {
                                            attrs: { height: "150px" },
                                            model: {
                                              value: _vm.textQuestionamento,
                                              callback: function ($$v) {
                                                _vm.textQuestionamento = $$v
                                              },
                                              expression: "textQuestionamento",
                                            },
                                          }),
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "btn-popup px-12",
                                              attrs: {
                                                color: "primary",
                                                type: "filled",
                                                disabled:
                                                  _vm.textQuestionamento
                                                    .length === 0,
                                              },
                                              on: {
                                                click: _vm.sendQuestionario,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-semibold pr-2 pl-2",
                                                },
                                                [_vm._v("Enviar")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-popup",
                                        {
                                          staticClass: "termos-aceite-modal",
                                          attrs: {
                                            title: "",
                                            active:
                                              _vm.assinarNovoTermoDeAceiteProposta,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "vw-row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "vx-col w-full" },
                                              [
                                                _c("h4", [
                                                  _vm._v("Termo e declaração"),
                                                ]),
                                                _c("small", [
                                                  _c("b", [
                                                    _vm._v(
                                                      "Leia os termos para a solicitação de emissão da\n                        apólice"
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "vw-row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-col w-full",
                                                  staticStyle: {
                                                    "background-color":
                                                      "'#f2f2f2'",
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "text-justify",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.TermoAceite
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vw-row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-col w-full",
                                                },
                                                [
                                                  _c(
                                                    "vs-checkbox",
                                                    {
                                                      staticClass:
                                                        "mt-8 mb-4 mx-0",
                                                      model: {
                                                        value:
                                                          _vm.aceitouTermosDeAceiteProposta,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.aceitouTermosDeAceiteProposta =
                                                            $$v
                                                        },
                                                        expression:
                                                          "aceitouTermosDeAceiteProposta",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Declaro que li e estou ciente das condições acima.\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vw-row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vx-col w-full text-right",
                                                },
                                                [
                                                  _c(
                                                    "vs-button",
                                                    {
                                                      staticClass:
                                                        "button-default mr-5",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelarEmissao()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Cancelar\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-button",
                                                    {
                                                      staticClass:
                                                        "button-primary",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.aceitouTermosDeAceiteProposta,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.validaDataVigencia()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Emitir\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "vs-popup",
                                        {
                                          staticClass:
                                            "validacao-data-vigente-modal",
                                          attrs: {
                                            title: "",
                                            active: _vm.editaDataVigente,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "vw-row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "vx-col w-full" },
                                              [
                                                _c("h4", [_vm._v("Aviso")]),
                                                _c("small", [
                                                  _c("b", [
                                                    _vm._v(
                                                      "A data de vigência da proposta não pode ser\n                        retroativa."
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "vw-row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-col w-full",
                                                  staticStyle: {
                                                    "background-color":
                                                      "'#f2f2f2'",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.mensagemRetornoData
                                                        ) +
                                                        "."
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vw-row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-col w-full",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Deseja alterar?"),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vw-row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vx-col w-full text-right",
                                                },
                                                [
                                                  _c(
                                                    "vs-button",
                                                    {
                                                      staticClass:
                                                        "button-default mr-5",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelarEdicaoDatas()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Cancelar\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-button",
                                                    {
                                                      staticClass:
                                                        "button-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.confirmarEmissao(
                                                            true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Confirma\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "vs-popup",
                                        {
                                          staticClass:
                                            "modal-explicativo-emissao-mapfre",
                                          attrs: {
                                            title: "",
                                            active:
                                              _vm.explicativoEmissaoMapfre,
                                            "button-close-hidden": true,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "vw-row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full text-center modal-explicativo-emissao-mapfre-content",
                                              },
                                              [
                                                _c("h4", [_vm._v("Atenção")]),
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      Sua solicitação está sendo processada. Caso a Apólice/Boleto(s) ainda não esteja(m) disponível(eis) para download na próxima tela, vá para a área de consulta de propostas ou apólices e verifique se está(ão) disponível(eis). Você também receberá no seu e-mail a confirmação da emissão da apólice.\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "vw-row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vx-col w-full text-center",
                                                },
                                                [
                                                  _c(
                                                    "vs-button",
                                                    {
                                                      staticClass:
                                                        "button-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.prosseguirEmissaoMapfre()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Prosseguir\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm.podeExibirTagCanalCorretorMapfre
                        ? _c("TagCanalCorretorMapfre", {
                            staticClass: "mb-6",
                            attrs: {
                              tipoCanalCorretora: _vm.tipoCanalCorretora,
                              view: "editar-proposta",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "tab-content",
                        { attrs: { title: "Dados do tomador" } },
                        [
                          _vm.proposta.Status != 3 && _vm.proposta.PolicyHolder
                            ? _c("dados-tomador", {
                                attrs: {
                                  tomadorProposta: _vm.proposta.PolicyHolder,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "tab-content",
                        { attrs: { title: "Dados do segurado" } },
                        [
                          _vm.proposta.Status != 3 && _vm.proposta.PolicyHolder
                            ? _c("dados-segurado", {
                                attrs: {
                                  cnpjTomador:
                                    _vm.proposta.PolicyHolder.CpfCnpj,
                                  uniqueId: _vm.proposta.PolicyHolder.UniqueId,
                                  idTomador: _vm.proposta.PolicyHolder.Id,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "tab-content",
                        { attrs: { title: "Dados do risco" } },
                        [
                          _vm.proposta.Status != 3 && _vm.proposta.Insured
                            ? _c("dados-risco", {
                                attrs: {
                                  seguradoUniqueId:
                                    _vm.proposta.Insured.UniqueId,
                                  seguradoId: _vm.proposta.Insured.Id,
                                  idTomador: _vm.proposta.PolicyHolder.Id,
                                  tomadorUniqueId:
                                    _vm.proposta.PolicyHolder.UniqueId,
                                  tipoCanalCorretora: _vm.tipoCanalCorretora,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "tab-content",
                        { attrs: { title: "Dados financeiros" } },
                        [
                          _c("dados-financeiros", {
                            attrs: { blockInputs: _vm.blockInputs },
                            on: {
                              saveProposal: function ($event) {
                                return _vm.salvarProposta()
                              },
                            },
                          }),
                          _vm.mostraCorretagem && _vm.deveExibirAgenciaMapfre
                            ? _c("h4", { staticClass: "mt-10" }, [
                                _vm._v("Dados corretagem"),
                              ])
                            : _vm._e(),
                          _vm.mostraCorretagem && _vm.deveExibirAgenciaMapfre
                            ? _c(
                                "vs-row",
                                [
                                  _c(
                                    "vs-col",
                                    { staticClass: "w-1/6" },
                                    [
                                      _c("ValidationProvider", {
                                        staticClass: "md:w-1/2 w-full md:pr-3",
                                        attrs: {
                                          name: "Agência",
                                          rules: "required|min:4",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "taxa-aplicada flex",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v("Agência"),
                                                      ]),
                                                      _c(
                                                        "vx-tooltip",
                                                        {
                                                          attrs: {
                                                            text: "Agência do corretor.",
                                                            position: "right",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons pl-2",
                                                            },
                                                            [_vm._v("info")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("vs-input", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: ["####"],
                                                        expression: "['####']",
                                                      },
                                                    ],
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      size: "large",
                                                      value:
                                                        _vm.proposta
                                                          .BrokerAgency,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.updateCorretagem(
                                                          {
                                                            BrokerAgency:
                                                              $event,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(errors[0]) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    { staticClass: "w-1/6" },
                                    [
                                      _c("ValidationProvider", {
                                        staticClass: "md:w-1/2 w-full md:pr-3",
                                        attrs: {
                                          name: "Matricula",
                                          rules: "required|min:2",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "taxa-aplicada flex",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v("Matricula"),
                                                      ]),
                                                      _c(
                                                        "vx-tooltip",
                                                        {
                                                          attrs: {
                                                            text: "Matricula do corretor.",
                                                            position: "right",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons pl-2",
                                                            },
                                                            [_vm._v("info")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("vs-input", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: [
                                                          "A#",
                                                          "A##",
                                                          "A###",
                                                          "A####",
                                                          "A#####",
                                                          "A######",
                                                          "A#######",
                                                        ],
                                                        expression:
                                                          "[\n                    'A#',\n                    'A##',\n                    'A###',\n                    'A####',\n                    'A#####',\n                    'A######',\n                    'A#######'\n                  ]",
                                                      },
                                                    ],
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      size: "large",
                                                      value:
                                                        _vm.proposta
                                                          .BrokerRegistration,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.updateCorretagem(
                                                          {
                                                            BrokerRegistration:
                                                              $event,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(errors[0]) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }